.spinner-box {
  background-color: #2b315e;
  width: 400px;
  height: 400px;
  margin: 7em auto;
  padding-top: 50px;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.loading-white {
  color: white;
}