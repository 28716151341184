

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

th {
  cursor: pointer;
  background-color: #2b315e !important;
  color: white;
}

tfoot {
  font-weight: 800;
  color: blue;
}

.navbar-custom {
  background-color: #2b315e !important;
}

.highUsage {
  text-align: center;
  font-weight: bold;
  background-color: #f44336;
}



@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';