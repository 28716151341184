body {
  /* background-color: #a19ea3; */
  font-family: 'Ubuntu', sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
/*  background-image: url('../../images/RAYNET-login-bg.png'); */
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main {
  background-color: #494c4e;
  width: 400px;
  height: 450px;
  margin: 7em auto;
  padding-top: 30px;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ffffff;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.pass-Label {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 14px;
}


.border-radius-20 {
  border-radius: 20%;
}

.un {
  width: 85%;
  color: rgb(130, 156, 170);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(252, 250, 250);
  padding: 10px 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgb(92, 77, 77);
  margin-bottom: 0px;
  margin-left: 46px;
  text-align: center;
  margin-bottom: 0px;
  font-family: 'Ubuntu', sans-serif;
}

.un-pass {
  width: 85%;
  color: rgb(130, 156, 170);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(252, 250, 250);
  padding: 10px 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgb(92, 77, 77);
  margin-left: 46px;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Ubuntu', sans-serif;
}


form.form1 {
  padding-top: 40px;
}

.pass {
  width: 85%;
  color: rgb(38, 50, 56);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  background: rgba(252, 250, 250);
  padding: 10px 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgb(92, 77, 77);
  margin-top: 0px;
  margin-left: 46px;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Ubuntu', sans-serif;
}

.un:focus,
.pass:focus {
  border: 2px solid rgba(0, 0, 0, 0.18) !important;

}

.submit {
  cursor: pointer;
  border-radius: .5em;
  color: #fff;
  background: linear-gradient(to right, #172151, #222fa1);
  border: 0;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  margin-left: 68%;
  font-size: 18px;
  box-shadow: 0 0 20px 1px rgba(13, 27, 120, 0.5);
}

.forgot {
  cursor: pointer;
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #a19fdb;
  padding-top: 0px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 50px;
  padding-left: 50px;
}

.help {
  margin-top: -10px;
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #a19fdb;
  padding-top: 0px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 50px;
  padding-left: 50px;

}

.requestAccess {
  margin-top: -80px;
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #a19fdb;
  padding-top: 0px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 50px;
  padding-left: 50px;

}

.bad-password {
  color: red;
  text-align: center;
  font-size: 1.5em;
}

.nothing {
  color: #494c4e;
  text-align: center;
  font-size: 1.5em;
}

a {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  text-decoration: none
}

@media (max-width: 600px) {
  .main {
    border-radius: 0px;
  }
}

.right {
  float: right;
  padding: 10px;
  margin-right: 25px;
  margin-bottom: 30px;
}

.left {
  float: left;
  padding: 10px;
  margin-left: 45px;
  margin-bottom: 30px;
}